<!-- <div class="container-fluid posiiton-relative">
  <div class="row justify-content-center">
    <div class="col-auto">
      <p class="mb-0 py-3">© 2021 McMillan Security Systems</p>
    </div>
  </div>
</div> -->

<div class="container-fluid position-relative">
  <div class="row justify-content-center top-container py-5">
    <div class="col-auto mb-3 mb-md-0  mr-md-5 mr-lg-4 mr-xl-5">
      <img class="logo" src="../../assets/mss-logo.png">
    </div>
    <div class="col-auto links my-auto d-none d-lg-block">
      <a class="row my-3" routerLink="/systems"><h5 class="ml-auto">Systems</h5></a>
      <a class="row" routerLink="/contact-us"><h5 class="ml-auto">Contact Us</h5></a>
    </div>
    <div class="col-auto my-auto mx-4 d-none d-lg-block">
      <div class="vl"></div>
    </div>
    <div class="col-auto my-auto">
      <a href="tel:4159927096" class="row">
        <div class="col-auto icon my-auto">
          <img class="my-auto phone-icon" src="../../assets/phone-icon.png">
        </div>
        <div class="col-auto my-auto ml-2">
          <h5 class="my-auto">415.992.7096</h5>
        </div>
      </a>
      <a href="mailto:securitysupport@mcmillanlv.com" class="row my-4">
        <div class="col-auto icon my-auto">
          <img class="my-auto mail-icon" src="../../assets/mail-icon.png">
        </div>
        <div class="col-auto my-auto ml-2">
          <h5 class="my-auto">securitysupport@mcmillanlv.com</h5>
        </div>
      </a>
      <a href="https://goo.gl/maps/5zHWshwi4ywWktqu5" target="_blank" rel="noopener noreferrer" class="row">
        <div class="col-auto icon my-auto">
          <img class="my-auto mail-icon" src="../../assets/address-icon.png">
        </div>
        <div class="col-auto my-auto ml-2">
          <h5 class="my-auto">39 Dorman Ave<br>San Francisco, CA</h5>
        </div>
      </a>
    </div>
  </div>
  <div class="row justify-content-center bottom-container py-3">
    <div class="col-12 text-center">
      <p class="my-0">© 2021 McMillan Security Systems | All Rights Reserved</p>
    </div>
  </div> 
</div>
