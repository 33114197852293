<div class="container-fluid systems py-5 position-relative">
  <video appParallax [ratio]=".2" id="Video1" class="w-100 position-absolute" appVideo loop muted oncanplay="this.play()" onloadedmetadata="this.muted = true">
    <source src="../../assets/systems/mss-systems-banner.mp4" type="video/mp4">
  </video>
  <div class="row py-5 my-5">
    <div class="col-auto px-0 py-5 my-5 mx-auto">
      <h1 @title class="systems-title position-relative">Our Systems</h1>
    </div>
  </div>
</div>


<!-- <div class="container-fluid banner">
  <div class="gradient"></div>
  <div class="row banner-content pt-3">
    <div class="col-auto mx-auto pt-5">
      <h1 class="mt-0 pt-3 mb-5">Our Systems</h1>
    </div>
  </div>
</div> -->

<div class="container-fluid">
  <div class="row">
    <div class="col-12 px-0">
      <div class="accordion" id="accordionExample">

        <div #AccessControl id="AccessControl" class="card">
          <div class="card-header py-0 my-0" id="headingOne">
            <h5 class="mb-1">
              <button id="BtnOne" (click)="expand('AccessControl');" class="h-100 w-100 text-left pt-3 pb-1 " data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                <div class="row">
                  <div class="col-auto pb-1"><img class="arrow" [class.arrow-flip]="selected === 'AccessControl' || selected === 'AccessControlinit'" src="../../assets/systems/arrow.png"></div>
                  <div class="col-auto pl-4"> Access Control</div>
                </div>
              </button>
            </h5>
          </div>
          <div id="collapseOne" class="collapse" [class.collapsing]="selected === 'AccessControl'" [class.show]="selected === 'AccessControlinit'" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div class="card-body pt-0 pb-5">
              <div class="opacity"></div>
              <div class="container-fluid pt-5">
                <div class="row justify-content-center pt-md-2">
                  <div class="col-10 col-xl-8">
                    <div class="row justify-content-center">
                      <div class="col-12 col-sm-6 col-lg-4 logo-col py-2">
                        <img src="../../assets/systems/alcatraz.png" alt="Alcatraz AI">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col py-2">
                        <img src="../../assets/systems/access_control/brivo.png" alt="Brivo">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col py-2">
                        <img src="../../assets/systems/access_control/RS2.png" alt="RS2">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col py-2">
                        <img src="../../assets/systems/access_control/amag.png" alt="AMAG Technology">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col py-2">
                        <img src="../../assets/systems/access_control/hid.png" alt="HID">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col py-2">
                        <img src="../../assets/systems/access_control/s2.png" alt="S2 Security">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col py-2">
                        <img src="../../assets/systems/access_control/invixium.png" alt="Invixium">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col py-2">
                        <img src="../../assets/systems/access_control/orion.png" alt="Orion Entrance Control, Inc.">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col py-2">
                        <img src="../../assets/systems/access_control/alvarado.png" alt="Alvarado">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col py-2">
                        <img src="../../assets/systems/access_control/smarter-security.png" alt="Software House">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col py-2">
                        <img src="../../assets/systems/access_control/swhouse.png" alt="Smarter Security">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col py-2">
                        <img src="../../assets/systems/access_control/openpath.png" alt="OpenPath">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div #CCTV id="CCTV" class="card">
          <div class="card-header py-0 my-0" id="headingTwo">
            <h5 class="mb-1">
              <button (click)="expand('CCTV');" id="BtnTwo" class="h-100 w-100 text-left pt-3 pb-1 collapsed" aria-expanded="false" aria-controls="collapseTwo" data-toggle="collapse" data-target="#collapseTwo">
                <div class="row">
                  <div class="col-auto pb-1"><img class="arrow" [class.arrow-flip]="selected === 'CCTV' || selected === 'CCTVinit'" src="../../assets/systems/arrow.png"></div>
                  <div class="col-auto pl-4"> CCTV</div>
                </div>
              </button>
            </h5>
          </div>
          <div id="collapseTwo" class="collapse" [class.collapsing]="selected === 'CCTV'" [class.show]="selected === 'CCTVinit'" aria-labelledby="headingTwo" data-parent="#accordionExample">
            <div class="card-body">
              <div class="opacity"></div>
              <div class="container-fluid pt-3">
                <div class="row justify-content-center pt-md-2">
                  <div class="col-10 col-xl-8">
                    <div class="row justify-content-center">
                      <div class="col-12 col-sm-6 col-lg-4 logo-col pb-4">
                        <img src="../../assets/systems/cctv/avigilon2.png" alt="Avigilon">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col pb-4">
                        <img src="../../assets/systems/cctv/axis2.png" alt="Axis Communications">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col pb-4">
                        <img src="../../assets/systems/cctv/bosch2.png" alt="Bosch Security">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col pb-4">
                        <img src="../../assets/systems/cctv/eagle-eye.png" alt="Eagle Eye Networks Inc.">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col pb-4">
                        <img src="../../assets/systems/cctv/exacq2.png" alt="Exacq systems">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col pb-4">
                        <img src="../../assets/systems/cctv/hanwha2.png" alt="Hanwha Techwin America">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col pb-4">
                        <img src="../../assets/systems/cctv/milestone.png" alt="Milestone">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col pb-4">
                        <img src="../../assets/systems/cctv/salient2.png" alt="Salient">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col pb-4">
                        <img src="../../assets/systems/cctv/verkada.png" alt="Verkada">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col pb-4">
                        <img src="../../assets/systems/cctv/qognify.png" alt="Qognify">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div #IntrusionDetection id="IntrusionDetection" class="card">
          <div class="card-header py-0 my-0" id="headingThree">
            <h5 class="mb-1">
              <button id="BtnThree" (click)="expand('IntrusionDetection');" class="h-100 w-100 text-left pt-3 pb-1 collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <div class="row">
                  <div class="col-auto pb-1"><img class="arrow" [class.arrow-flip]="selected === 'IntrusionDetection' || selected === 'IntrusionDetectioninit'" src="../../assets/systems/arrow.png"></div>
                  <div class="col-auto pl-4"> Intrusion Detection</div>
                </div>
              </button>
            </h5>
          </div>
          <div id="collapseThree" class="collapse" [class.collapsing]="selected === 'IntrusionDetection'" [class.show]="selected === 'IntrusionDetectioninit'" aria-labelledby="headingThree" data-parent="#accordionExample">
            <div class="card-body">
              <div class="opacity"></div>
              <div class="container-fluid py-5">
                <div class="row justify-content-center pt-md-2">
                  <div class="col-10 col-xl-8">
                    <div class="row justify-content-center">
                      <div class="col-12 col-sm-6 col-lg-4 logo-col pb-4">
                        <img src="../../assets/systems/cctv/bosch2.png" alt="Bosch security">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col pb-4">
                        <img src="../../assets/systems/intrusion_detection/dsc.png" alt="DSC from Tyco security products">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col pb-4">
                        <img src="../../assets/systems/intrusion_detection/honeywell.png" alt="Honeywell Systems%2C">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div #PhoneEntry id="PhoneEntry" class="card">
          <div class="card-header py-0 my-0" id="headingFour">
            <h5 class="mb-1">
              <button id="BtnFour" (click)="expand('PhoneEntry');" class="h-100 w-100 text-left pt-3 pb-1 collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                <div class="row">
                  <div class="col-auto pb-1"><img class="arrow" [class.arrow-flip]="selected === 'PhoneEntry' || selected === 'PhoneEntryinit'" src="../../assets/systems/arrow.png"></div>
                  <div class="col-auto pl-4"> Phone Entry & Communication Systems</div>
                </div>
              </button>
            </h5>
          </div>
          <div id="collapseFour" class="collapse" [class.collapsing]="selected === 'PhoneEntry'" [class.show]="selected === 'PhoneEntryinit'" aria-labelledby="headingFour" data-parent="#accordionExample">
            <div class="card-body">
              <div class="opacity"></div>

              <div class="container-fluid py-5">
                <div class="row justify-content-center pt-md-2 py-5">
                  <div class="col-10 col-xl-8">
                    <div class="row justify-content-center">
                      <div class="col-12 col-sm-6 col-lg-4 logo-col pb-4">
                        <img src="../../assets/systems/phone_entry/aiphone.png" alt="AIPhone phone entry">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col pb-4">
                        <img src="../../assets/systems/phone_entry/butterflymx.png" alt="ButterflyMX">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col pb-4">
                        <img src="../../assets/systems/phone_entry/code-blue.png" alt="Code Blue security">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col pb-4">
                        <img src="../../assets/systems/phone_entry/comelit.png" alt="Comelit Professional">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col pb-4">
                        <img src="../../assets/systems/phone_entry/doorking.png" alt="DoorKing (DKS)">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col pb-4">
                        <img src="../../assets/systems/phone_entry/siedle.png" alt="SSS Siedle">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col pb-4">
                        <img src="../../assets/systems/phone_entry/talk-a-phone.png" alt="Talkaphone">
                      </div>
                      <div class="col-12 col-sm-6 col-lg-4 logo-col pb-4">
                        <img src="../../assets/systems/phone_entry/viking.png" alt="Viking security">
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
