import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit{
  title = 'mss';

  constructor(private router: Router) {}

  ngOnInit(): void {
    let skipNotification = localStorage.getItem('displayUpdate');
    if (skipNotification == null) {
      setTimeout(function() {
        let moved = document.getElementById('Moved');
        moved.classList.add('moved-show');
        this.localStorage.setItem('displayUpdate', true);
      }, 4000);
    }
  }

  showModal($event) {
    if ($event == true) {
      document.getElementById("Modal").classList.add("show-modal");
    }
    else {
      document.getElementById("Modal").classList.remove("show-modal");
    }
  }

  onActivate(event) {
    window.scroll(0,0);
  }

  noticeRemove() {
    let notice = document.getElementById('Moved');
    notice.classList.remove('moved-show');
  }

  seeLocation() {
    let notice = document.getElementById('Moved');
    notice.classList.remove('moved-show');
    this.router.navigate(['contact-us'], {fragment: 'Location'});
  }

}
