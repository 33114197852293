import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { animate, transition, trigger, state, style } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-systems',
  templateUrl: './systems.component.html',
  styleUrls: ['./systems.component.less'],
  animations: [
    trigger('title', [
      transition(':enter', [
        style({ transform: 'translateY(-30px)', opacity: 0 }),
        animate('.5s .5s ease', style({ transform: 'translateY(0px)', opacity: 1 }))
      ])
    ])
  ]
})
export class SystemsComponent implements OnInit, AfterViewInit {

  @ViewChild('AccessControl', { static: true }) AC: any;
  @ViewChild('CCTV', { static: true }) CCTV: any;

  fragment: any;

  constructor(
    private active_route: ActivatedRoute,
    private router: Router,
    private title: Title
  ) {
    title.setTitle("MSS | Systems");
    active_route.fragment.pipe(first()).subscribe(data => {
      console.log("sub: " + data);
      this.selected = data + "init";
    });
  }

  selected: string = "";
  AccessControl;

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
  }

  expand(id: string) {
    if (this.selected == id) {
      // this.selected = '';
    }
    else {
      this.router.navigate(['/systems'], { fragment: id, replaceUrl: false});
      this.selected = id;
    }
  }

}
