import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { animate, transition, state, trigger, style, query, animateChild, group, keyframes } from '@angular/animations';

interface System {
  name: string,
  description: string,
  img: string
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less'],
  animations: [
    trigger('bounce', [
      transition(':enter', [
        style({opacity: 0, transform: 'translateY(0px)'}),
        animate('.4s 2s ease-out',
          style({opacity: 1, transform: 'translateY(-6px)'})
        )
      ]),
      state('up', style({transform: 'translateY(-6px)'})),
      state('down', style({transform: 'translateY(0px)'})),
      state('scrolled', style({'font-size': '2rem', opacity: 0})),
      transition('up => down', animate('.5s ease-in')),
      transition('down => up', animate('.6s ease-out')),
      transition('* => scrolled', animate('.6s ease-out'))
    ]),
    trigger('businessTrigger', [
      transition(':enter', [
        style({transform: "translateY(-50px)", opacity: 0}),
        animate('.4s .7s ease-in',
          style({transform: "translateY(0px)", opacity: 1})
        )
      ])
    ]),
    trigger('systemIcon', [
      state('hide-left', style({ transform: 'translateX(-70px)', opacity: 0 })),
      state('hide-right', style({ transform: 'translateX(70px)', opacity: 0 })),
      state('show', style({ transform: 'translateX(0px)', opacity: 1 })),
      transition('hide-left => show', [
        animate('.4s ease-out')
      ]),
      transition('hide-right => show', [
        animate('.4s ease-out')
      ]),
    ]),
    trigger('systemHR', [
      state('hide-left', style({ transform: 'translateX(-100%)'})),
      state('hide-right', style({ transform: 'translateX(100%)'})),
      state('show', style({ transform: 'translateX(0px)' })),
      transition('hide-left => show', [
        animate('.4s .3s ease-out')
      ]),
      transition('hide-right => show', [
        animate('.4s .3s ease-out')
      ]),
    ]),
    trigger('systemTitle', [
      state('hide-left', style({ transform: 'translateX(-35px)', opacity: 0 })),
      state('hide-right', style({ transform: 'translateX(35px)', opacity: 0 })),
      state('show', style({ transform: 'translateX(0px)', opacity: 1 })),
      transition('hide-left => show', [
        animate('.4s .5s ease-out')
      ]),
      transition('hide-right => show', [
        animate('.4s .5s ease-out')
      ]),
    ]),
    trigger('flipCard1', [
      state('unflip', style({ transform: 'rotateY(0deg)' })),
      state('flip', style({ transform: 'rotateY(90deg)' })),
      transition('unflip => flip', [
        animate('.2s')
      ]),
      transition('flip => unflip', [
        animate('.2s .2s ease-out')
      ]),
    ]),
    trigger('flipCard2', [
      state('unflip', style({ transform: 'rotateY(90deg)' })),
      state('flip', style({ transform: 'rotateY(0deg)' })),
      transition('unflip => flip', [
        animate('.2s .2s ease-out')
      ]),
      transition('flip => unflip', [
        animate('.2s')
      ]),
    ])
  ]
})
export class HomeComponent implements OnInit {

  scroll_indicator_ani: string = 'up';

  initialTop: number = 0
  selected_market: string = "one";

  systems_ani = [
    'hide-left',
    'hide-right',
    'hide-left',
    'hide-right'
  ]

  abstract

  systems: Array<System> = [
    {
      name: "Access Control",
      description: "Lorem ipsum custom applications, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      img: "../../assets/access-control.svg"
    },
    {
      name: "CCTV",
      description: "Lorem ipsum custom applications, consectetur adipiscing elit, sed do  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      img: "../../assets/cctv.svg"
    },
    {
      name: "Intrusion Detection",
      description: "Lorem ipsum custom applications, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      img: "../../assets/intrusion-detection.svg"
    },
  ]

  flip_card: Array<string> = [
    'unflip',
    'unflip',
    'unflip'
  ]

  @ViewChild("Cover") cover: ElementRef;
  @ViewChild("AccessControl") AccessControl: ElementRef;
  @ViewChild("CCTV") CCTV: ElementRef;
  @ViewChild("IntrusionDetection") IntrusionDetection: ElementRef;
  @ViewChild("PhoneEntry") PhoneEntry: ElementRef;

  constructor(private title: Title) {
    title.setTitle("MSS | Home");
  }

  ngOnInit(): void {}

  @HostListener('window:scroll', ['$event']) onScroll($event, str: string){
    if(window.pageYOffset >= 20 && this.scroll_indicator_ani != 'scrolled') {
      console.log("scroll detect");
      this.scroll_indicator_ani = 'scrolled';
    }
    if (window.pageYOffset >= this.AccessControl.nativeElement.offsetTop + (window.innerHeight/3) + (this.cover.nativeElement.offsetHeight-window.innerHeight)) {
      this.systems_ani[0] = 'show';
    }
    if (window.pageYOffset >= this.CCTV.nativeElement.offsetTop + (window.innerHeight/3) + (this.cover.nativeElement.offsetHeight-window.innerHeight)) {
      this.systems_ani[1] = 'show';
    }
    if (window.pageYOffset >= this.IntrusionDetection.nativeElement.offsetTop + (window.innerHeight/3) + (this.cover.nativeElement.offsetHeight-window.innerHeight)) {
      this.systems_ani[2] = 'show';
    }
    if (window.pageYOffset >= this.PhoneEntry.nativeElement.offsetTop + (window.innerHeight/3) + (this.cover.nativeElement.offsetHeight-window.innerHeight)) {
      this.systems_ani[3] = 'show';
    }
  }

  bounceEnd(event) {
    if (this.scroll_indicator_ani != 'scrolled') {
      if (this.scroll_indicator_ani == 'up') {
        this.scroll_indicator_ani = 'down';
      }
      else {
        this.scroll_indicator_ani = 'up';
      }
    }
  }

  flipCard(index: number, state: string) {
    this.flip_card[index] = state;
  }

  marketDescription(id: string) {
    console.log(id);
    this.selected_market = id;
  }

}
