<app-nav class="position-fixed" (showModal)="showModal($event)"></app-nav>
<router-outlet (activate)="onActivate($event);"></router-outlet>
<app-footer></app-footer>
<div id="Modal"></div>
<div id="Moved" class="container-fluid pb-5 moved">
    <div class="row justify-content-center h-100 pb-5">
        <div class="col-12 col-sm-10 col-md-8 col-lg-5 col-xl-4 my-auto text-center">
            <h1 class="notice"><i>NOTICE</i></h1>
            <div class="notification pt-3 pb-3 px-4 text-center mb-2 mt-4">
                <img src="../assets/we've-moved.svg">
            </div>
            <div class="row">
                <div class="col-6 pr-1">
                    <button (click)="noticeRemove();" class="w-100 py-1 okay">Okay</button>
                </div>
                <div class="col-6 pl-1">
                    <button (click)="seeLocation();" class="w-100 py-1 see-more">See More</button>
                </div>
            </div>
        </div>
    </div>
</div>
