<div #Cover class="cover container-fluid position-relative pt-5">
  <div class="video-opacity z-3"></div>
  <div class="row justify-content-center z-3 position-relative h-100 mt-5 mt-md-0">
    <div class="col-12 my-auto">
      <div class="row justify-content-center">
        <div class="col-9 col-sm-6 col-md-5 col-lg-auto">
          <img src="../assets/mss-logo.png">
        </div>
        <div class="col-11 col-lg-auto text-center text-lg-left mt-auto mb-2 px-0">
          <h1 class="my-0 cover-header">The <span @businessTrigger class="business">Business</span> <br class="display-lg-block mt-2">End of Security</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-11 col-md-8 col-lg-auto text-center text-lg-left mx-auto">
          <h2 class="mb-0 mb-lg-2">Independently owned and operated since 1999</h2>
          <p class="pb-lg-5 mb-lg-5 description">
            McMillan Security Systems is the San Francisco Bay Area’s leading security systems integrator. Our world-class management and technically-trained union installation crews ensure an <strong>exceptional</strong> level of detail, customer service, and value that is unmatched in the <em>commercial</em> marketplace
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row scroll-indicator">
    <p [@bounce]="scroll_indicator_ani" (@bounce.done)="bounceEnd($event)" class="mx-auto mb-2">Scroll For More</p>
  </div>
  <div id="Video" class="video">
    <video appVideo loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
      <source src="../../assets/mss-home-banner.mp4" type="video/mp4">
    </video>
  </div>
</div>
<div class="container-fluid pt-4 pt-xl-5 pb-5 markets position-relative">
  <div id="carouselMarkets" class="carousel slide h-100 w-100" data-ride="carousel" data-interval="false">
    <div class="carousel-inner h-100 w-100">
      <div class="carousel-item active h-100 w-100">
        <img class="d-block" src="../assets/contracting.jpg" alt="First slide">
      </div>
      <div class="carousel-item h-100 w-100">
        <img class="d-block" src="../assets/highrise-core.jpg" alt="First slide">
      </div>
      <div class="carousel-item h-100 w-100">
        <img class="d-block" src="../assets/highrise-residential.jpg" alt="First slide">
      </div>
      <div class="carousel-item h-100 w-100">
        <img class="d-block" src="../assets/health-care.jpg" alt="First slide">
      </div>
      <div class="carousel-item h-100 w-100">
        <img class="d-block" src="../assets/tenant-improvement.jpg" alt="First slide">
      </div>
    </div>
  </div>
  <div class="opacity"></div>
  <div class="row px-5 px-lg-4">
    <div class="col-auto">
      <h1 class="header text-center">Markets We Serve</h1>
    </div>
  </div>
  <div class="row pb-lg-5 pb-xl-3">
    <div class="col order-lg-2 text-center mb-3 mt-1 mt-lg-2 d-none d-xl-block">
      <div class="market-description text-left mt-2 mt-lg-0 mt-xl-2 pl-lg-2 pl-xl-4 pr-lg-4 pr-xl-5" [class.show-description]="selected_market === 'one'">
        <h4 class="mb-lg-3 mb-xl-4">For over the past 20 years, we've proudly provided concept-to-completion electronic security systems to some of the largest and most reputable GC and electrical contracting firms.</h4>
        <p>MSS is recognized as the Bay Area's premier specialist in destination dispatch, base building access control, visitor management systems, electronic turnstiles and customized parking solutions amongst other services. With no direct or indirect affiliations to any electrical or other contracting firms, projects are always safe from possible exposure of outside competition.</p>
      </div>
      <div class="market-description text-left mt-2 mt-lg-0 mt-xl-2 pl-lg-2 pl-xl-4 pr-lg-4 pr-xl-5" [class.show-description]="selected_market === 'two'">
        <h4 class="mb-lg-3 mb-xl-4">McMillan Security Systems has successfully completed numerous San Francisco landmark high-rise core and shell projects that have included destination dispatch elevator integration with KONE, Mitsubishi, Schindler, OTIS and others.</h4>
        <p>These large-scale projects often require months, if not years to bring to full completion and requires an exceptional level of management, coordination and technical skillset. Consistency in the control and deployment of these key elements ensure scheduling benchmarks are continually met throughout the various project phases, resulting in on-time and on-budget delivery.</p>
      </div>
      <div class="market-description text-left mt-2 mt-lg-0 mt-xl-2 pl-lg-2 pl-xl-4 pr-lg-4 pr-xl-5" [class.show-description]="selected_market === 'three'">
        <h4 class="mb-lg-3 mb-xl-4">In addition to the high-rise commercial market McMillan Security Systems serves, we’ve also provided security systems design and installations for several high-rise residential buildings that populate San Francisco’s skyline.</h4>
        <p>Design and delivery of these system packages include the latest technology in closed-circuit television, access control, visitor management and elevator dispatch. We ensure an unmatched level security, safety, and convenience while providing peace of mind for residents and protection of property.</p>
      </div>
      <div class="market-description text-left mt-2 mt-lg-0 mt-xl-2 pl-lg-2 pl-xl-4 pr-lg-4 pr-xl-5" [class.show-description]="selected_market === 'four'">
        <h4 class="mb-lg-3 mb-xl-4">For over 20-years, McMillan Security Systems has been serving Health-Care Campuses to provide design, installation and support services ranging from new MOBs to multi-floor remodel to retrofitted security solutions.</h4>
        <p>Hospitals are challenging and complex environments requiring specific knowledge of safety processes, infection controls, OSHPD regulations, various certification and associated skillsets that ensure safety and security for staff and patients alike, are always first and foremost.</p>
      </div>
      <div class="market-description text-left mt-2 mt-lg-0 mt-xl-2 pl-lg-2 pl-xl-4 pr-lg-4 pr-xl-5" [class.show-description]="selected_market === 'five'">
        <h4 class="mb-lg-3 mb-xl-4">Ranging from modest phone entry systems to multi-floor restack or TI's, we can accomodate any size project.</h4>
        <p>Working closely with architects, GC's, and electrical contractors, our individually assigned PM's reamain involved from the pre-con phase until project close-out.This accountability ensures trouble-free and timely delivery of our systems. We encourage end-users to take advantage of our experienced, courteous and responsive resource team to design a systems package that suites specific needs without the added costs or complexities often associated with contracted design consultaion.</p>
      </div>
    </div>
    <div class="col-12 col-lg-auto accordion order-lg-1 mt-lg-1 mb-xl-4 pl-3 pl-md-4 pl-xl-4 my-auto" id="mobileAccordion">
      <div id="headingOne">
        <h2 class="mb-0">
          <button (click)="marketDescription('one');" data-target="#collapseOne" data-toggle="collapse" class="px-2 py-0 text-left" type="button"  aria-expanded="true" aria-controls="collapseOne">
            <span>General & Electrical Contractors</span>
            <div class="w-100 h-100 position-absolute" data-target="#carouselMarkets" data-slide-to="0"></div>
          </button>
        </h2>
      </div>
      <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#mobileAccordion">
        <div class="accordion-item px-4 pt-0 pb-2 d-xl-none">
          <h4 class="mb-lg-3 mb-xl-4">For over the past 20 years, we've proudly provided concept-to-completion electronic security systems to some of the largest and most reputable GC and electrical contracting firms.</h4>
          <p>MSS is recognized as the Bay Area's premier specialist in destination dispatch, base building access control, visitor management systems, electronic turnstiles and customized parking solutions amongst other services. With no direct or indirect affiliations to any electrical or other contracting firms, projects are always safe from possible exposure of outside competition.</p>
        </div>
      </div>
      <div id="headingTwo">
        <h2 class="mb-0">
          <button (click)="marketDescription('two');" class="px-2 py-0 collapsed text-left" data-target="#collapseTwo" data-toggle="collapse" type="button" aria-expanded="false" aria-controls="collapseTwo">
            <span>Commercial High-Rise Core &amp; Shell</span>
            <div class="w-100 h-100 position-absolute" data-target="#carouselMarkets" data-slide-to="1"></div>
          </button>
        </h2>
      </div>
      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#mobileAccordion">
        <div class="accordion-item px-4 pt-0 pb-2 d-xl-none">
          <h4 class="mb-lg-3 mb-xl-4">McMillan Security Systems has successfully completed numerous San Francisco landmark high-rise core and shell projects that have included destination dispatch elevator integration with KONE, Mitsubishi, Schindler, OTIS and others.</h4>
         <p>These large-scale projects often require months, if not years to bring to full completion and requires an exceptional level of management, coordination and technical skillset. Consistency in the control and deployment of these key elements ensure scheduling benchmarks are continually met throughout the various project phases, resulting in on-time and on-budget delivery.</p>
        </div>
      </div>
      <div id="headingThree">
        <h2 class="mb-0">
          <button (click)="marketDescription('three');" class="px-2 py-0 collapsed text-left" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            <span>High-Rise Residential</span>
            <div class="w-100 h-100 position-absolute" data-target="#carouselMarkets" data-slide-to="2"></div>
          </button>
        </h2>
      </div>
      <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#mobileAccordion">
        <div class="accordion-item px-4 pt-0 pb-2 d-xl-none">
          <h4 class="mb-lg-3 mb-xl-4">In addition to the high-rise commercial market McMillan Security Systems serves, we’ve also provided security systems design and installations for several high-rise residential buildings that populate San Francisco’s skyline.</h4>
          <p>Design and delivery of these system packages include the latest technology in closed-circuit television, access control, visitor management and elevator dispatch. We ensure an unmatched level security, safety, and convenience while providing peace of mind for residents and protection of property.</p>
        </div>
      </div>
      <div id="headingFour">
        <h2 class="mb-0">
          <button (click)="marketDescription('four');" class="px-2 py-0 collapsed text-left" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
            <span>Health Care</span>
            <div class="w-100 h-100 position-absolute" data-target="#carouselMarkets" data-slide-to="3"></div>
          </button>
        </h2>
      </div>
      <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#mobileAccordion">
        <div class="accordion-item px-4 pt-0 pb-2 d-xl-none">
          <h4 class="mb-lg-3 mb-xl-4">In addition to the high-rise commercial market McMillan Security Systems serves, we’ve also provided security systems design and installations for several high-rise residential buildings that populate San Francisco’s skyline.</h4>
          <p>Design and delivery of these system packages include the latest technology in closed-circuit television, access control, visitor management and elevator dispatch. We ensure an unmatched level security, safety, and convenience while providing peace of mind for residents and protection of property.</p>
        </div>
      </div>
      <div id="headingFive">
        <h2 class="mb-0">
          <button (click)="marketDescription('five');" class="px-2 py-0 collapsed text-left" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
            <span>Tenant Improvement</span>
            <div class="w-100 h-100 position-absolute" data-target="#carouselMarkets" data-slide-to="4"></div>
          </button>
        </h2>
      </div>
      <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#mobileAccordion">
        <div class="accordion-item px-4 pt-0 pb-2 d-xl-none">
          <h4 class="mb-lg-3 mb-xl-4">Ranging from modest phone entry systems to multi-floor restack or TI's, we can accomodate any size project.</h4>
          <p>Working closely with architects, GC's, and electrical contractors, our individually assigned PM's reamain involved from the pre-con phase until project close-out.This accountability ensures trouble-free and timely delivery of our systems. We encourage end-users to take advantage of our experienced, courteous and responsive resource team to design a systems package that suites specific needs without the added costs or complexities often associated with contracted design consultaion.</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid process">
  <div class="row justify-content-center mt-3 text-center py-5">
    <div class="col-12">
      <h1 class="header">The MSS Process</h1>
    </div>
    <div (mouseenter)="flipCard(0, 'flip');" (mouseleave)="flipCard(0, 'unflip');" class="col-10 col-sm-8 col-md-6 col-lg-3 px-0 mt-4">
      <div [@flipCard1]="flip_card[0]" class="process-card card-front py-5">
        <object class="w-75" data="../assets/design.svg" type="image/svg+xml"></object>
        <h4 class="mt-5 pt-2">Systems Design</h4>
      </div>
      <div [@flipCard2]="flip_card[0]" class="process-card card-back py-2 position-absolute px-3 px-md-4">
        <p class="my-auto">Many of today’s existing security systems often contain an evolution of legacy technologies. As advanced integration specialists, we can effectively migrate existing devices and infrastructures into modern system solutions, preserving your existing investment while delivering sophisticated solutions in the most cost-effective manner.</p>
      </div>
    </div>
    <!-- <div class="col-8 col-lg-1 py-4 px-0 mt-lg-5">
      <object data="../assets/dots.svg" type="image/svg+xml"></object>
    </div> -->
    <div (mouseenter)="flipCard(1, 'flip');" (mouseleave)="flipCard(1, 'unflip');" class="col-10 col-sm-8 col-md-6 col-lg-3 mx-5 px-0 mt-4">
      <div [@flipCard1]="flip_card[1]" class="process-card card-front py-5">
        <object class="w-75" data="../assets/installation.svg" type="image/svg+xml"></object>
        <h4 class="mt-5">Installation</h4>
      </div>
      <div [@flipCard2]="flip_card[1]" class="process-card card-back py-2 position-absolute px-3 px-md-4">
        <p class="my-auto">All installations are performed in-house, by our factory-trained and certified teams. The combination of controls and technical field talent results in exceptional levels of installation quality. Much of our team was currated from our conception to provide for the most experienced and talented union-labor force in the industry.</p>
      </div>
    </div>
    <!-- <div class="col-8 col-lg-1 py-4 px-0 mt-lg-5">
      <object data="../assets/dots.svg" type="image/svg+xml"></object>
    </div> -->
    <div (mouseenter)="flipCard(2, 'flip');" (mouseleave)="flipCard(2, 'unflip');" class="col-10 col-sm-8 col-md-6 col-lg-3 support px-0 mt-4">
      <div [@flipCard1]="flip_card[2]" class="process-card card-front py-5">
        <object class="" data="../assets/support.svg" type="image/svg+xml"></object>
        <h4 class="mt-4 pt-3">24/7 Support</h4>
      </div>
      <div [@flipCard2]="flip_card[2]" class="process-card card-back py-2 position-absolute px-3 px-md-4">
        <p class="my-auto">Our commitment to assist our clients around the clock has earned us a reputation as an effective, results-oriented partner that you can depend on.</p>
      </div>
    </div>
  </div>
</div>
<div class="systems container-fluid py-5 position-relative">
  <div class="row justify-content-center">
    <div class="col-10 col-md-10 col-xl-9 system">
      <a #AccessControl routerLink="/systems" fragment="AccessControl" class="row justify-content-center">
        <div class="col-auto col-md my-auto">
          <div class="row">
            <div class="col-12 col-md-auto ml-auto mb-2">
              <a [@systemIcon]="systems_ani[0]" class="position-relative system-icon">
                <object data="../assets/access-control.svg" type="image/svg+xml">
                </object>
              </a>
            </div>
            <div class="col d-none d-md-block my-auto px-0 overflow-hidden">
              <hr [@systemHR]="systems_ani[0]">
            </div>
          </div>
        </div>
        <div class="col-12 col-md-auto my-auto text-center">
          <h2 [@systemTitle]="systems_ani[0]" class="mb-0">Access Control</h2>
        </div>
        <div class="col-12 col-md-2 my-auto px-0 overflow-hidden">
          <hr [@systemHR]="systems_ani[0]" class="mb-4 mt-2 d-md-none">
        </div>
      </a>
      <a #CCTV routerLink="/systems" fragment="CCTV" class="row justify-content-center mt-5 mt-md-0">
        <div class="col-auto col-md my-auto order-md-2">
          <div class="row">
            <div class="col-12 col-md-auto ml-auto mb-2 order-md-2">
              <a [@systemIcon]="systems_ani[1]" class="position-relative">
                <object data="../assets/cctv.svg" type="image/svg+xml">
                </object>
              </a>
            </div>
            <div class="col d-none d-md-block my-auto order-md-1 px-0 overflow-hidden">
              <hr [@systemHR]="systems_ani[1]">
            </div>
          </div>
        </div>
        <div class="col-12 col-md-auto my-auto text-center order-md-1">
          <h2 [@systemTitle]="systems_ani[1]" class="mb-0">CCTV</h2>
        </div>
        <div class="col-12 col-md-2 my-auto px-0 overflow-hidden">
          <hr [@systemHR]="systems_ani[1]" class="mb-4 mt-2 d-md-none">
        </div>
      </a>
      <a #IntrusionDetection routerLink="/systems" fragment="IntrusionDetection" class="row justify-content-center mt-5 mt-md-0">
        <div class="col-auto col-md my-auto">
          <div class="row">
            <div class="col-12 col-md-auto ml-auto mb-2">
              <div [@systemIcon]="systems_ani[2]" class="position-relative">
                <object data="../assets/intrusion-detection.svg" type="image/svg+xml">
                </object>
              </div>
            </div>
            <div class="col d-none d-md-block my-auto px-0 overflow-hidden">
              <hr [@systemHR]="systems_ani[2]">
            </div>
          </div>
        </div>
        <div class="col-12 col-md-auto my-auto text-center">
          <h2 [@systemTitle]="systems_ani[2]" class="mb-0">Intrusion Detection</h2>
        </div>
        <div class="col-12 col-md-2 my-auto px-0 overflow-hidden">
          <hr [@systemHR]="systems_ani[2]" class="mb-4 mt-2 d-md-none">
        </div>
      </a>
      <a #PhoneEntry routerLink="/systems" fragment="PhoneEntry" class="row justify-content-center mt-5 mt-md-0">
        <div class="col-auto col-md my-auto order-md-2">
          <div class="row">
            <div class="col-12 col-md-auto ml-auto mb-2 order-md-2">
              <a [@systemIcon]="systems_ani[3]" class="position-relative">
                <object data="../assets/phone-entry.svg" type="image/svg+xml">
                </object>
              </a>
            </div>
            <div class="col d-none d-md-block my-auto order-md-1 px-0 overflow-hidden">
              <hr [@systemHR]="systems_ani[3]">
            </div>
          </div>
        </div>
        <div class="col-12 col-md-auto my-auto text-center order-md-1">
          <h2 [@systemTitle]="systems_ani[3]" class="mb-0">Phone Entry</h2>
        </div>
        <div class="col-12 col-md-2 my-auto px-0 overflow-hidden">
          <hr [@systemHR]="systems_ani[3]" class="mb-4 mt-2 d-md-none">
        </div>
      </a>
    </div>
  </div>
  <div class="opacity"></div>
</div>
