import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private auth: AngularFireAuth
  ) { }

  check_recaptcha(recaptcha: string) {
    const callable = this.fns.httpsCallable('helloWorld1');
    return callable({ recaptcha: recaptcha }).toPromise();
  }

  service_email(first_name: string, last_name: string, company: string, email: string, phone: string, message: string) {
    var now = new Date();
    var id = now.getMonth()+1+"."+now.getDay()+"."+now.getFullYear()+", "+now.getHours()+":"+now.getMinutes();
    var body_text = first_name+" "+last_name+"\n"+company+"\n"+email+"\n";
    var body_html = first_name+" "+last_name+"<br/>"+company+"<br/>"+email+"<br/>";
    if(phone != "") {
      body_text += phone+"\n";
      body_html += phone+"<br/>";
    }
    body_text += "\n\""+message+"\"";
    body_html += "<br/>\""+message+"\"";
    return this.afs.collection("mail").doc("request - "+id).set({
      to: "mss.contactform@gmail.com",
      message: {
        subject: "Incoming Service Request",
        text: body_text,
        html: body_html,
      },
    });
  }
  confirmation_email(first_name: string, last_name: string, company: string, email: string, phone: string, message: string) {
    var now = new Date();
    var id = now.getMonth()+1+"."+now.getDay()+"."+now.getFullYear()+", "+now.getHours()+":"+now.getMinutes();
    var body_text = first_name+","+"\n\nThank you for connecting with McMillan Security Systems. A member of our team will contact you shortly regarding your request:\n\""+message+"\"";
    var body_html = first_name+",<br/><br/>Thank you for connecting with McMillan Security Systems. A member of our team will contact you shortly regarding your request:<br/>\""+message+"\"";
    return this.afs.collection("mail").doc("confirmation - "+id).set({
      to: email,
      message: {
        subject: "Service Request Confirmation",
        text: body_text,
        html: body_html,
      },
    });
  }
}
