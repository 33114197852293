// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyB_4TlHgix6iXUY08PmhR9ZLRWg7T3Zstw",
    authDomain: "mcmillan-security.firebaseapp.com",
    projectId: "mcmillan-security",
    storageBucket: "mcmillan-security.appspot.com",
    messagingSenderId: "988338341919",
    appId: "1:988338341919:web:3fc10f6c19256d8c552d2a",
    measurementId: "G-GK1YDG68P2"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
