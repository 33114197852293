<div class="container-fluid py-5 position-relative">
  <img appParallax [ratio]=".1" [@bgHue]="show_confirmation" class="bg" src="../../assets/bridge.jpg">
  <div class="opacity"></div>
  <div class="row justify-content-center mt-5">
    <div class="col-12 col-md-10 col-xl-8 pt-3 text-center">
      <h1 @title class="mt-4">Contact Us</h1>
      <h2 @h2 class="mb-1 mt-4 mt-md-1">Secure the McMillan Connection</h2>
      <h5 @h5 class="mt-1">FOR 24/7 EMERGENCY SERVICE CALL:  &nbsp;<a href="tel:4159927096"><span class="highlight">1&#8209;(415)&#8209;992&#8209;7096</span></a></h5>
      <h2 [@showConfirmation]="show_confirmation" class="position-absolute mb-0">Thank you for connecting with McMillan Security Systems. A member of our team will connect with you within 24 hours</h2>
      <form [@hideForm]="show_confirmation" [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="needs-validation pb-4 px-1 px-sm-3 row justify-content-center position-relative pt-0 pt-md-1 mt-0 mt-md-2">
        <div *ngIf="form_submit && !show_confirmation" class="form-block position-absolute"></div>
        <mat-progress-spinner *ngIf="form_submit && !show_confirmation"
          class="example-margin"
          [color]="'primary'"
          [mode]="'indeterminate'">
        </mat-progress-spinner>
        <p [@errorMessage]="error_shake" id="ErrorMessage" class="mb-2">{{ error_message }}</p>
        <div class="col-6 mt-4 position-relative px-1 px-md-2">
          <div class="input-color"></div>
          <input [@invalid]="invalid[0]" id="FormFirstName" formControlName="first_name" (focus)="focus_input(0)" (focusout)="focusout_input(0, $event)" class="w-100 pl-3 pt-2 position-relative" type="text" required>
          <p [@focus]="input_focus_ani[0]" class="placeholder my-0">First Name<span class="highlight">*</span></p>
        </div>
        <div class="col-6 mt-4 position-relative px-1 px-md-2">
          <div class="input-color"></div>
          <input [@invalid]="invalid[1]" id="FormLastName" formControlName="last_name" (focus)="focus_input(1)" (focusout)="focusout_input(1, $event)" class="w-100 pl-3 pt-2 position-relative" type="text" required>
          <p [@focus]="input_focus_ani[1]" class="placeholder my-0">Last Name<span class="highlight">*</span></p>
        </div>
        <div class="col-6 mt-2 mt-sm-3 mt-md-4 position-relative px-1 px-md-2">
          <div class="input-color"></div>
          <input [@invalid]="invalid[2]" id="FormEmail" formControlName="email" (focus)="focus_input(2)" (focusout)="focusout_input(2, $event)" class="w-100 pl-3 pt-2 position-relative" type="email" email required>
          <p [@focus]="input_focus_ani[2]" class="placeholder my-0">Email<span class="highlight">*</span></p>
        </div>
        <div class="col-6 mt-2 mt-sm-3 mt-md-4 position-relative px-1 px-md-2">
          <div class="input-color"></div>
          <input [@invalid]="invalid[3]" id="FormPhone" formControlName="phone" (focus)="focus_input(3)" (focusout)="focusout_input(3, $event)" class="w-100 pl-3 pt-2 position-relative" type="text" required>
          <p [@focus]="input_focus_ani[3]" class="placeholder my-0">Phone<span class="highlight">*</span></p>
        </div>
        <div class="col-12 mt-2 mt-sm-3 mt-md-4 position-relative px-1 px-md-2">
          <div class="input-color"></div>
          <input [@invalid]="invalid[4]" id="FormCompany" formControlName="company" (focus)="focus_input(4)" (focusout)="focusout_input(4, $event)" class="w-100 pl-3 pt-2 position-relative" type="text" required>
          <p [@focus]="input_focus_ani[4]" class="placeholder my-0">Company<span class="highlight">*</span></p>
        </div>
        <div class="col-12 mt-2 mt-sm-3 mt-md-4 position-relative px-1 px-md-2">
          <div class="input-color"></div>
          <textarea [@invalid]="invalid[5]" id="FormMessage" formControlName="message" (focus)="focus_input(5)" (focusout)="focusout_input(5, $event)" class="w-100 pl-3 pt-3 position-relative" type="text" required></textarea>
          <p [@focus]="input_focus_ani[5]" class="placeholder my-0">How can we help?<span class="highlight">*</span></p>
        </div>
        <div class="col-auto mt-3 mt-md-4">
          <re-captcha (resolved)="resolved($event)"
            [class.invalid]="invalid[6]"
            formControlName="recaptcha"
            siteKey="6Lee5UsaAAAAAD0N7pz6ciYHpVXKspXvGrPxYdL8" required>
          </re-captcha>
        </div>
        <div class="col-10 col-md mt-3 mt-md-4 px-1 px-md-2">
          <button class="w-100 h-100 py-2 py-md-0" type="submit">Contact MSS</button>
        </div>
      </form>
    </div>
  </div>
</div>
<div id="Location" #Location class="container-fluid info position-relative">
  <div class="row justif-content-center position-relative py-4">
    <img [@bayArea]="bay_area" class="position-absolute h-100" src="../../assets/contact/bay-area.svg">
    <div class="col-12 col-md-5 text-center text-md-right my-auto pr-md-3 mr-xl-5">
      <h2 [@hoursTitle]="bay_area">Hour of Operation</h2>
      <h3 [@hours1]="bay_area">M-F 7:00-4:00</h3>
    </div>
    <div class="col-11 col-sm-10 col-md-7 col-xl-6 pr-md-5 mx-auto mr-md-auto mr-xl-5">
      <iframe class="w-100" height="350" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12618.838627766952!2d-122.3969892!3d37.7499552!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8d08271fad6a683a!2sMcMillan%20Security%20Systems%2C%20Inc.!5e0!3m2!1sen!2sus!4v1628292254230!5m2!1sen!2sus" widthframeborder="1" style="border:0;" allowfullscreen></iframe>
      <!-- <iframe class="w-100" height="350" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12618.83862776695!2d-122.3969892!3d37.74995520000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7510d1447d517d48!2sMcMillan+Security+Systems%2C+Inc.!5e0!3m2!1sen!2sus!4v1559175178797!5m2!1sen!2sus" widthframeborder="1" style="border:0" allowfullscreen></iframe> -->
    </div>
  </div>
</div>
