<nav class="container-fluid" [class.nav-background]="nav_background === true">
  <div class="row align-items-center h-100 pl-lg-1 pr-0 position-relative">
    <div class="col-auto home my-auto px-2 ml-3" [class.show-logo]="nav_background === true">
      <a class="row" routerLink="/home">
        <img class="display-inline d-none d-sm-block col-auto px-0 nav-logo" [class.show-logo]="nav_background === true" src="../../assets/mss-logo.png">
        <span class="my-auto col-auto pl-1 pl-sm-2">McMillan<br> Security Systems, Inc.</span>
      </a>
    </div>
    <div (click)="displayMobileMenu()" id="MenuOpen" class="d-lg-none col-auto ml-auto pr-4">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="33" height="26" viewBox="0 0 33 26">
        <defs>
          <clipPath id="clip-path">
            <rect width="33" height="26" fill="none"/>
          </clipPath>
        </defs>
        <g id="Repeat_Grid_32" data-name="Repeat Grid 32" clip-path="url(#clip-path)">
          <g transform="translate(-342.5 -22)">
            <line id="Line_37" data-name="Line 37" x2="41" transform="translate(342.5 23.5)" fill="none" stroke="#dedede" stroke-width="3"/>
          </g>
          <g transform="translate(-342.5 -11)">
            <line id="Line_37-2" data-name="Line 37" x2="41" transform="translate(342.5 23.5)" fill="none" stroke="#dedede" stroke-width="3"/>
          </g>
          <g transform="translate(-342.5)">
            <line id="Line_37-3" data-name="Line 37" x2="41" transform="translate(342.5 23.5)" fill="none" stroke="#dedede" stroke-width="3"/>
          </g>
        </g>
      </svg>
    </div>
    <ul id="Menu" class="d-none d-lg-flex ml-auto px-3 mt-0 mb-0">
      <li class="py-3 px-0 px-xl-2">
        <a class="nav-item d-block py-2 px-3" routerLink="/systems">Systems</a>
      </li>
      <li class="py-3 px-0 px-xl-2">
        <a class="nav-item d-block py-2 px-3" routerLink="/contact-us">Contact Us</a>
      </li>
    </ul>
  </div>
  <div id="MobileMenu" class="d-lg-none pt-3">
    <svg id="MenuClose" (click)="closeMobileMenu()" class="ml-auto mr-4 d-block" fill="white" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <title>Toggle Menu Close</title>
      <path d="M5.293 6.707l5.293 5.293-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0l5.293-5.293 5.293 5.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-5.293-5.293 5.293-5.293c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
    </svg>
    <ul class="px-4 pt-2">
      <li>
        <a class="d-block py-3 px-2" (click)="closeMobileMenu();" routerLink="/systems">Systems</a>
      </li>
      <li>
        <a class="d-block py-3 px-2" (click)="closeMobileMenu();" routerLink="/contact-us">Contact Us</a>
      </li>
    </ul>
  </div>
</nav>
