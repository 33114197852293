import { Component, OnInit, AfterViewInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';

import { EmailService } from '../email.service';
import { Router } from '@angular/router';
// import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.less'],
  animations: [
    trigger('title', [
      state("void", style({ transform: "translateX(-50px)", opacity: 0 })),
      transition(':enter', animate('.4s .4s ease', style({ transform: "translateX(0px)", opacity: 1 })))
    ]),
    trigger('h2', [
      state("void", style({ transform: "translateY(-20px)", opacity: 0 })),
      transition(':enter', animate('.4s .7s ease', style({ transform: "translateY(0px)", opacity: 1 })))
    ]),
    trigger('h5', [
      state("void", style({ transform: "translateY(-20px)", opacity: 0 })),
      transition(':enter', animate('.3s 1s ease', style({ transform: "translateY(0px)", opacity: 1 })))
    ]),
    trigger('bgHue', [
      state('false', style({ filter: 'hue-rotate(0deg)' })),
      state('true', style({ filter: 'hue-rotate(90deg)' })),
      transition('false => true', animate('2s ease'))
    ]),
    trigger('errorMessage', [
      state('true', style({ transform: 'scale(1)' })),
      state('false', style({ transform: 'scale(1)' })),
      transition('true <=> false', animate('.5s ease', keyframes([
        style({transform: 'translateX(5px)', offset: 0.1}),
        style({transform: 'translateX(-5px)', offset: 0.5}),
        style({transform: 'translateX(3px)', offset: 0.8}),
        style({transform: 'translateX(-2px)', offset: 0.9}),
        style({transform: 'translateX(0px)', offset: 1}),
      ])))
    ]),
    trigger('focus', [
      state('true', style({ transform: 'translateY(10%)', 'font-size': '.75rem' })),
      state('false', style({ transform: 'translateY(65%)', 'font-size': '1rem' })),
      transition('true <=> false', animate('.3s ease'))
    ]),
    trigger('invalid', [
      state('true', style({ 'border-bottom': '2px solid red' })),
      state('false', style({ 'border-bottom': 'none' })),
      transition('true <=> false', animate('.1s ease'))
    ]),
    trigger('hideForm', [
      state('false', style({ transform: 'translateX(0px)', opacity: 1, visibility: "visible" })),
      state('true', style({ transform: 'translateX(200px)', opacity: 0, visibility: "hidden" })),
      transition('false => true', animate('.4s ease-in'))
    ]),
    trigger('showConfirmation', [
      state('false', style({ transform: 'translateY(20px)', opacity: 0, visibility: "hidden" })),
      state('true', style({ transform: 'translateY(100px)', opacity: 1, visibility: "visible" })),
      transition('false => true', animate('.5s .4s ease'))
    ]),
    trigger('bayArea', [
      state('false', style({ transform: 'translateX(-100%)' })),
      state('true', style({ transform: 'translateX(0)' })),
      transition('false => true', animate('.7s ease'))
    ]),
    trigger('hoursTitle', [
      state('false', style({ transform: 'translateY(-30px)', opacity: 0 })),
      state('true', style({ transform: 'translateY(0)', opacity: 1 })),
      transition('false => true', animate('.3s .3s ease'))
    ]),
    trigger('hours1', [
      state('false', style({ transform: 'translateX(-30px)', opacity: 0 })),
      state('true', style({ transform: 'translateX(0px)', opacity: 1 })),
      transition('false => true', animate('.3s .6s ease'))
    ]),
    trigger('hours2', [
      state('false', style({ transform: 'translateX(-30px)', opacity: 0 })),
      state('true', style({ transform: 'translateX(0px)', opacity: 1 })),
      transition('false => true', animate('.3s .9s ease'))
    ]),
    trigger('hours3', [
      state('false', style({ transform: 'translateX(-30px)', opacity: 0 })),
      state('true', style({ transform: 'translateX(0px)', opacity: 1 })),
      transition('false => true', animate('.3s 1.2s ease'))
    ]),
  ]
})
export class ContactComponent implements OnInit {

  contactForm = new FormGroup({
    recaptcha: new FormControl(null, Validators.required),
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    company: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required),
  });

  input_focus_ani: Array<boolean> = [
    false,
    false,
    false,
    false,
    false,
    false
  ]
  invalid: Array<boolean> = [
    false,
    false,
    false,
    false,
    false,
    false,
    false
  ]

  error_shake: boolean = false;
  error_message: string = "";
  email_sent: boolean = false;

  form_submit: boolean = false;
  loading: boolean = false;
  show_confirmation: boolean = false;
  bay_area: boolean = false;

  @ViewChild('Location') locationEl: ElementRef

  constructor(
    private email_service: EmailService,
    private title: Title,
    private router: Router
  ) {
    title.setTitle("MSS | Contact Us");
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    let urlFragment = this.router.parseUrl(this.router.url).fragment;
    if (urlFragment == "Location") {
      // console.log(this.locationEl.nativeElement.offsetTop);
      // window.
      this.locationEl.nativeElement.scrollIntoView();
    }
  }

  @HostListener('window:scroll', ['$event']) onScroll($event, str: string){
    if(window.pageYOffset >= 300) {
      this.bay_area = true;
    }
  }

  shake_error() {
    this.error_shake = !this.error_shake;
  }

  send_mail(first_name: string, last_name: string, email: string, phone: string, company: string, message: string, recaptcha: string) {
    this.form_submit = true;
    this.email_service.check_recaptcha(recaptcha).then(res => {
      console.log(res);
      this.email_service.service_email(first_name, last_name, company, email, phone, message).then(service_res => {
        this.email_sent = true;
        this.show_confirmation = true;
      }).catch(service_err => {
        console.error(service_err);
        alert("Something went wrong with Service email");
        this.form_submit = false;
      });
      this.email_service.confirmation_email(first_name, last_name, company, email, phone, message).catch(conf_err => {
        console.error(conf_err);
      });
    }).catch(err => {
      this.form_submit = false;
      console.log(err);
      alert("Something went wrong with recaptcha");
    });
  }

  onSubmit() {
    const first_name = this.contactForm.controls.first_name;
    const last_name = this.contactForm.controls.last_name;
    const email = this.contactForm.controls.email;
    const phone = this.contactForm.controls.phone;
    const company = this.contactForm.controls.company;
    const message = this.contactForm.controls.message;
    const recaptcha = this.contactForm.controls.recaptcha;
    if (this.contactForm.valid) {
      this.error_message = "";
      for (var i=0; i<this.invalid.length; i++) {
        this.invalid[i] = false;
      }
      this.send_mail(first_name.value, last_name.value, email.value, phone.value, company.value, message.value, recaptcha.value);
    }
    else {
      console.log(this.contactForm);
      if(!first_name.valid || !last_name.valid || !phone.valid || !company.valid || !message.valid || !recaptcha.valid) {
        this.error_message = "**Missing required fields";
        if(!first_name.valid) {
          this.invalid[0] = true;
        } else {
          this.invalid[0] = false;
        }
        if(!last_name.valid) {
          this.invalid[1] = true;
        } else {
          this.invalid[1] = false;
        }
        if(!email.valid) {
          this.invalid[2] = true;
        } else {
          this.invalid[2] = false;
        }
        if(!phone.valid) {
          this.invalid[3] = true;
        } else {
          this.invalid[3] = false;
        }
        if(!company.valid) {
          this.invalid[4] = true;
        } else {
          this.invalid[4] = false;
        }
        if(!message.valid) {
          this.invalid[5] = true;
        } else {
          this.invalid[5] = false;
        }
        if(!recaptcha.valid) {
          this.invalid[6] = true;
        } else {
          this.invalid[6] = false;
        }
      } else if (email.value == '') {
        this.error_message = "**Missing required fields";
      } else {
        this.error_message = "**Invalid email address";
      }
      this.shake_error();
    }
  }

  resolved(event) {
    console.log(event);
  }

  focus_input(index: number) {
    this.input_focus_ani[index] = true;
  }

  focusout_input (index: number, event: any) {
    if (event.target.value == "") {
      this.input_focus_ani[index] = false;
    }
  }

}
