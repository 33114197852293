import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.less']
})
export class NavComponent implements OnInit {
  @Output() showModal = new EventEmitter<boolean>();
  @HostListener('window:scroll', ['$event']) onScrollEvent($event){
    if(window.pageYOffset >= 20) {
      this.nav_background = true;
    }
    else if (window.pageYOffset == 0) {
      this.nav_background = false;
    }
  }

  constructor() { }

  mobile_menu: any;
  nav_background: boolean = false;

  ngOnInit(): void {
    this.mobile_menu = document.getElementById("MobileMenu");
  }

  displayMobileMenu() {
    this.mobile_menu.classList.add("open-from-right");
    this.showModal.emit(true);
  }

  closeMobileMenu() {
    this.mobile_menu.classList.remove("open-from-right");
    this.showModal.emit(false);
  }

}
